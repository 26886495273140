import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import { getSceneById } from '../data/les-scenes'
import { DynamicStaticImage } from '../components/ArtistAtSchool/dynamicStaticImage'
import BigButton from '../components/Ui/BigButton'
import getTicketURL from '../utils/TicketURL'

const Stage = ({ pageContext }) => {
  const { id } = pageContext
  const scene = getSceneById(id)
  const title = scene.name
  const description = scene.description
  const image = scene.getImageLocalPath
  const videoUrl = scene.videoUrl
  const bannerImage = scene.getBannerImageLocalPath
  const htmlList = scene.htmlList
  const customLink = scene.customLink
  const hideTicketButton = scene.hideTicketButton

  const pageTitle = 'Les Scènes'
  return (
    <Wolflayout title={pageTitle}>
      <FestifHelmet htmlAttributes={{ lang: 'fr' }}>
        <script src="https://lepointdevente.com/plugins/cart/js?locale=fr_CA&amp;ga=UA-32197713-2"></script>
      </FestifHelmet>

      <Banner title={pageTitle} />

      <div className="w-full py-0 px-8 lg:px-16">
        <section className="artiste-page">

          {/* NOM DE LA SCÈNE */}
          <h2 className={'uppercase font-ohno text-[65px] text-white text-center leading-none tracking-wider my-8'}>
            {title}
          </h2>
          <br />

          {/* TOP VIDEO */}
          {
            videoUrl
              ? <div className="max-w-[1920px] justify-center mx-auto">
            <center>
              <div className="text-center w-full">
                <video muted autoPlay loop playsInline className="rounded-3xl">
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            </center>
          </div>
              : null
          }
          {
            !videoUrl && bannerImage
              ? <div className="max-w-[1420px] justify-center mx-auto w-full">
              <center>
                <div className="text-center w-full">
                <DynamicStaticImage
                  localPath={bannerImage}
                  alt={title}
                  className="max-w-[1420px] rounded-3xl mb-6" />
                </div>
              </center>
            </div>
              : null
          }

        </section>

        {/* DESCRIPTION */}
        { description
          ? <div className='text-center text-white my-12 lg:mx-64 md:mx-24 mx-9 text-xl leading-2 font-openSans font-bold tracking-wide'>
              <h1>{description}</h1>
            </div>
          : null }

        <div className="block lg:flex">
          {/* PHOTO DE LA SCÈNE */}
          <div className='text-center block lg:w-1/2 w-full'>
            <div className="m-9 relative max-w-[500px] mx-auto">
              <DynamicStaticImage
                localPath={image}
                alt={title}
                className="max-w-[920px] rounded-3xl mb-6" />
            </div>
          </div>

          {/* DÉTAIL DE LA SCÈNE */}
          <div className="lg:w-1/2 w-full px-9 relative text-left text-white py-2 text-lg font-openSans max-w-[500px] flex items-center">
            <ul className='list-disc w-full'>
              {htmlList?.map((htmlContent, i) => {
                return <li key={i} className='my-2 stage-list' dangerouslySetInnerHTML={{ __html: htmlContent }}></li>
              })}

            {
              customLink
                ? <div className='flex'>
                <p className="mx-0"><BigButton href={customLink.link} text={customLink.title} largeText={false}/></p>
              </div>
                : null
            }
            </ul>

          </div>

        </div>

        {/* BILLETTERIE */}
        {hideTicketButton
          ? <></>
          : <div className='w-full flex text-center'>
          <p className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} mx-auto`}><BigButton href={getTicketURL()} text="BILLETS" largeText={true}/></p>
        </div>
        }

        {/* PROGRAMMATION */}
        <div className='w-full flex text-center'>
          <p className="mx-auto"><BigButton href="/programmation" text="Programmation" largeText={true}/></p>
        </div>

        {/* BOUTON DE RETOUR */}
        <div className='w-full flex text-center'>
          <p className="mx-auto"><BigButton href="/infos/scenes#info_content" text="Revenir aux scènes" largeText={false} target='_self'/></p>
        </div>

        <div className="h-12"></div>
      </div>
    </Wolflayout>
  )
}

export default Stage
